import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SquidexService } from '@landing-app/services/squidex.service'
import { Subscription } from 'rxjs'
import { isScullyGenerated, TransferStateService } from '@scullyio/ng-lib'
import { take } from 'rxjs/operators'
@Component({
  selector: 'flowaccount-faq-accordion-squidex',
  templateUrl: './faq-accordion-squidex.component.html',
  styleUrls: ['./faq-accordion-squidex.component.scss'],
})
export class FaqAccordionSquidexComponent implements OnInit {
  @Input() FAQHeader: string
  @Input() contentFaq: string
  private subscriptionService: Subscription[] = []
  public ServiceList: any
  private FAQ_SERVICE_KEY = 'faq-service-key'
  private faqQuestions: number
  public headingDefault = this.translate.currentLang === 'th' ? 'คำถามที่พบบ่อย (FAQ)' : 'Frequently Asked Questions'
  constructor(
    public translate: TranslateService,
    public readonly squidexService: SquidexService,
    protected tstate: TransferStateService,
    public cdref: ChangeDetectorRef,
  ) {}
  ngOnInit(): void {
    // After scully builded
    if (isScullyGenerated()) {
      this.tstate.useScullyTransferState(this.FAQ_SERVICE_KEY, this.ServiceList).subscribe((data: object[]) => {
        this.ServiceList = data
        this.cdref.markForCheck()
      })
    } else {
      // run every build
      this.getFaqService()
    }
  }
  async getFaqService() {
    this.subscriptionService.push(
      this.squidexService
        .getContentSquidexApiOrder(this.contentFaq)
        .pipe(take(1))
        .subscribe((data: object[]) => {
          this.ServiceList = data
          this.tstate.setState(this.FAQ_SERVICE_KEY, data)
          this.cdref.markForCheck()
        }),
    )
  }
  ngOnDestroy() {
    this.subscriptionService.forEach((x) => x.unsubscribe())
  }
  onClickToggleFAQ(index: number) {
    if (this.faqQuestions === index) {
      this.faqQuestions = null
    } else {
      this.faqQuestions = index
    }
  }
  checkCurrentFaqTab(index: number) {
    return this.faqQuestions === index
  }
}
