import { Inject, Injectable, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { GROWTHBOOK_CONFIG, GrowthBookConfig, GrowthBookService } from '@flowaccount/landing'
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core'
import { Keepalive } from '@ng-idle/keepalive'
import { TranslateService } from '@ngx-translate/core'
import { CookieService } from 'ngx-cookie-service'
import { DeviceDetectorService } from 'ngx-device-detector'
import { Subscription, filter, interval } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'
import { EventTrackerService } from './event-tracker.service'
import { environment } from '@landing-src/environments/environment'
import {
  UserAnalyticCollector,
  UserAnalyticDataEvent,
  UserAnalyticEvents,
} from '@landing-app/models/event-collector.model'
import { TrafficSourceService } from './traffic-source.service'
import { Experiment, Result, TrackingData } from '@growthbook/growthbook'
import { GrowthBookEventData } from '@landing-app/models/growthbook-event-data'

export enum IdleState {
  NotStarted = 0,
  Idle = 2,
  NotIdle = 4,
  Timedout = 6,
}

@Injectable({
  providedIn: 'root',
})
export class VisitorService implements OnDestroy {
  private readonly SESSION_TIMEOUT = 30 * 60 * 1000 // 30 minutes in ms
  private readonly MAX_SESSION_DURATION = 24 * 60 * 60 * 1000 // 24 hours in ms
  private readonly TIME_CHECK_SESSION = 60 * 1000
  private readonly lastActivityTimeName = 'last_activity_time'
  private readonly sessionStartTimeName = 'session_start_time'
  private lastActivityTime: number = Date.now()
  private sessionStartTime: number = Date.now()
  public idleState = IdleState.NotStarted
  private keepalive$: Subscription
  private routerEvent: Subscription
  private _currentUrl: string
  private _previousUrl: string
  private isCsrfGeneratorOn = false
  private _utmSource = ''
  private _utmMedium = ''
  private _utmCampaign = ''
  private _utmTerm = ''
  private _utmContent = ''
  private experiment: Experiment<any> = null
  private result: Result<any> = null

  constructor(
    @Inject(GROWTHBOOK_CONFIG) private growthBookConfig: GrowthBookConfig,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private cookieService: CookieService,
    private growthBookService: GrowthBookService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private eventTrackerService: EventTrackerService,
    private trafficSourceService: TrafficSourceService,
  ) {
    this.watch()
    this.setUtmParameters()
  }

  initializeRouterEvent(): void {
    this._currentUrl = this.router.url
    this.routerEvent = this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(async (event: NavigationEnd) => {
        this._previousUrl = this._currentUrl
        this._currentUrl = event.url
        await this.loadGrowthBook()
        await this.checkCsrfGenerator()
        await this.trackPageViewMetric()
      })
  }

  private async loadGrowthBook(): Promise<void> {
    this.growthBookService = await GrowthBookService.getInstance(this.growthBookConfig)
    await this.growthBookService.getGrowthBook().setAttributes({
      id: this.getUserId(),
      utmId: '',
      utmSource: this._utmSource,
      utmMedium: this._utmMedium,
      utmCampaign: this._utmCampaign,
      utmTerm: this._utmTerm,
      utmContent: this._utmContent,
      userTrafficSource: this.trafficSourceService.getTrafficSource(),
      userType: this.isReturningVisitorType(),
      userAgent: navigator?.userAgent ?? '',
      userOS: this.deviceService.os,
      userOsVersion: this.deviceService.os_version,
      userBrowser: this.deviceService.browser,
      userDeviceType: this.deviceService.deviceType,
      userOrientationType: this.deviceService.orientation,
      userLanguage: this.translate.currentLang,
      userCountry: '',
      userTimezone: '',
      userCity: '',
      userRegion: '',
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      userResolution: window.innerWidth + 'x' + window.innerHeight,
      userSubscriptionStatus: this.cookieService.get('SubscriptionStatus') || 'New',
      userVisitDate: this.getVisitDate(),
      userVisitTime: this.getVisitTime(),
      userDayOfWeek: this.getDayOfWeek(),
      previousUrl: this._previousUrl,
      currentUrl: this._currentUrl,
      referralUrl: document?.referrer ?? '',
    })
  }

  async checkCsrfGenerator(): Promise<void> {
    this.isCsrfGeneratorOn = this.growthBookService.getGrowthBook().isOn('landing-csrf-generator')
    if (this.isCsrfGeneratorOn) {
      this.eventTrackerService.getCsrfToken()
    }
  }

  private async trackPageViewMetric(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.growthBookService.getGrowthBook().setTrackingCallback((experiment, result) => {
          const userAttributes = this.growthBookService.getGrowthBook().getAttributes()
          const pageViewPayload = {
            experimentName: experiment.name,
            experimentKey: experiment.key,
            dataId: '',
            dataName: '',
            variationId: result.variationId,
            variationName: result.name,
          }

          const userEventObject = {
            ...userAttributes,
            eventId: UserAnalyticEvents.PageView,
            createdAt: new Date(),
            payLoad: pageViewPayload,
          } as UserAnalyticCollector<unknown>

          this.setExperimentData(experiment, result)

          this.eventTrackerService.send(userEventObject)
          resolve()
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  private setUtmParameters(): void {
    this._utmSource = this.trafficSourceService.getUtms().utmSource
    this._utmMedium = this.trafficSourceService.getUtms().utmMedium
    this._utmCampaign = this.trafficSourceService.getUtms().utmCampaign
    this._utmContent = this.trafficSourceService.getUtms().utmContent
    this._utmTerm = this.trafficSourceService.getUtms().utmTerm
  }

  watch() {
    this.loadSessionData()

    this.idle.setIdle(15) // 15 seconds of inactivity
    this.idle.setTimeout(1800) // 30 minutes to timeout
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)

    this.idle.onIdleStart.subscribe(() => {
      if (this.idleState !== IdleState.Timedout) {
        this.idleState = IdleState.Idle
      }
    })

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = IdleState.NotIdle
      this.updateActivity()
    })

    this.idle.onTimeout.subscribe(() => {
      if (this.idleState !== IdleState.Timedout) {
        this.idleState = IdleState.Timedout
        this.startNewSession()
      }
      this.idle.stop()
      this.idle.watch()
    })

    this.keepalive.interval(this.TIME_CHECK_SESSION / 1000)
    this.keepalive.onPing.subscribe(() => {
      this.checkSession()
    })

    this.keepalive.start()
    this.idle.watch()
    this.idleState = IdleState.NotStarted
  }

  private updateActivity() {
    this.lastActivityTime = Date.now()
    this.cookieService.set(this.lastActivityTimeName, this.lastActivityTime.toString())
  }

  private loadSessionData() {
    const lastActivityTime = this.cookieService.get(this.lastActivityTimeName)
    const sessionStartTime = this.cookieService.get(this.sessionStartTimeName)

    if (lastActivityTime) {
      this.lastActivityTime = parseInt(lastActivityTime, 10)
    }

    if (sessionStartTime) {
      this.sessionStartTime = parseInt(sessionStartTime, 10)
    }
  }

  private checkSession() {
    const currentTime = Date.now()
    const inactivityDuration = currentTime - this.lastActivityTime
    const sessionDuration = currentTime - this.sessionStartTime

    if (inactivityDuration > this.SESSION_TIMEOUT || sessionDuration > this.MAX_SESSION_DURATION) {
      this.startNewSession()
    }
  }

  private startNewSession() {
    this.sessionStartTime = Date.now()
    this.cookieService.set(this.sessionStartTimeName, this.sessionStartTime.toString())
    this.determineUserType()
  }

  private determineUserType() {
    const isNewUser = !this.cookieService.get('fa_visit_bf')
    const expiryDate = new Date()
    expiryDate.setFullYear(expiryDate.getFullYear() + 1)

    if (isNewUser) {
      this.cookieService.set('fa_visit_bf', 'true', {
        expires: expiryDate,
        domain: environment.domain,
      })
    }
    this.growthBookService.getGrowthBook().setAttributes({
      ...this.growthBookService.getGrowthBook().getAttributes(),
      userType: this.isReturningVisitorType(),
    })
  }

  public isReturningVisitorType() {
    return this.cookieService.get('fa_visit_bf') ? 'Returning Visitor' : 'New Visitor'
  }

  public getUserId(): string {
    const userId = this.cookieService.get('userId')
    if (!userId) {
      const uuid = uuidv4()
      const DAY_IN_MS = 24 * 60 * 60 * 1000
      const expirationDate = new Date(new Date().getTime() + 30 * DAY_IN_MS)
      this.cookieService.set('userId', uuid, {
        expires: expirationDate,
        domain: environment.domain,
      })
      return uuid
    }
    return userId
  }

  public getVisitDate(): string {
    try {
      return new Date()?.toISOString()?.split('T')[0] || ''
    } catch (err) {
      return ''
    }
  }

  public getVisitTime() {
    return new Date().toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    })
  }

  public getDayOfWeek(): string {
    const getDayByNumber = new Date().getDay()
    if (getDayByNumber < 0 || getDayByNumber > 6 || isNaN(getDayByNumber)) {
      return 'Unknown'
    }
    const DAYS = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    }
    return DAYS[getDayByNumber]
  }

  public setExperimentData(experiment: Experiment<any>, result: Result<any>): void {
    this.experiment = experiment
    this.result = result
  }

  async track(eventId: UserAnalyticEvents, eventType: string, additionalData?: Record<string, any>): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (this.isGrowthBookExperiment()) {
          const eventData = GrowthBookEventData[eventId][eventType]
          const userAttributes = this.growthBookService.getGrowthBook().getAttributes()
          const trackingPayload = {
            ...eventData,
            experimentName: this.experiment.name,
            experimentKey: this.experiment.key,
            variationId: this.result.variationId,
            variationName: this.result.name,
            ...additionalData,
          }

          const userEventObject = {
            ...userAttributes,
            eventId: eventId,
            createdAt: new Date(),
            payLoad: trackingPayload,
          } as UserAnalyticCollector<unknown>

          this.eventTrackerService.send(userEventObject)
        }
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  private isGrowthBookExperiment(): boolean {
    return this.experiment && this.result && this.result.inExperiment
  }

  public clearExperimentData(): void {
    this.experiment = null
    this.result = null
  }

  stop() {
    if (this.keepalive$) {
      this.keepalive$.unsubscribe()
    }
    this.idle.stop()
  }

  ngOnDestroy(): void {
    this.stop()
    if (this.routerEvent) {
      this.routerEvent.unsubscribe()
    }
  }
}
